import { responseHandler } from "./responseHandler"

export const sendMessage = ({ phone, message }) => {
  const url = `${process.env.MARKETING_URL}/v2/video/invite`
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ap-stage": "staging",
    },
    body: JSON.stringify({ phone, message }),
  }).then(responseHandler)
}

export const getShortenUrl = ({ url, expiration }) => {
  const URL = `${process.env.MARKETING_URL}/v2/video/urlshortener`
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ap-stage": "staging",
    },
    body: JSON.stringify({ url, expiration }),
  }).then(responseHandler)
}
